<template>
  <div class="container flex flex-col justify-center items-center ">
    <svg-icon class=""
              type="iconwancheng"
              style="font-size: 66px; color: #00DE45;" />
    <p class="my-36 text-24 text-color-333 font-medium">专栏创建完成</p>
    <div>
      <router-link :to="{name: 'liveColumn_statistic', query: $route.query}">
        <a-button class="mr-80 bg-color-f text-admin-primary border-admin-primary">更多设置</a-button>
      </router-link>
      <router-link :to="{name: 'liveOpenClass_create', query: $route.query}">
        <a-button type="primary">去创建直播</a-button>
      </router-link>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import SvgIcon from "@/components/SvgIcon";

export default defineComponent({
  name: "LiveOpenClassCreateMiddle",

  components: {
    SvgIcon,
  },

  setup() {
    return {};
  },
});
</script>
<style lang='less' scoped>
.container {
  margin: 0 122px;
  margin-top: 5%;
  width: auto;
  height: 480px;
  background-color: fade(@color-primary, 10);
  border-radius: 24px;
}
</style>